<template>
  <f7-app v-bind="f7params">
    <f7-view main class="safe-areas" url="/" :animate="appAnimate"
             :browser-history="state.platform === 'web'"
             :browser-history-animate-on-load="true"
             :browser-history-animate="appAnimate"
             :ios-swipe-back="false" :md-swipe-back="false"></f7-view>
  </f7-app>
  <login-popup></login-popup>
  <ocean-login-popup></ocean-login-popup>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount, reactive, watch } from 'vue';
import { f7ready } from 'framework7-vue';
import { App } from '@/compositions/app.composition';
import { createDebugToast, hideSplashScreen } from '@/helpers/f7.helpers';
import { APP_ANIMATE, DEBUG } from '@/core/config/constants.config';
import { Plugins } from '@capacitor/core';
import { useI18n } from 'vue-i18n';
import { routes } from '@/router';
import LoginPopup from '@/views/LoginPopup.vue';
import { Session } from '@/compositions/session.composition';
import OceanLoginPopup from '@/views/OceanLoginPopup.vue';
import { registerNotifications } from '@/helpers/notifications.helpers';
import { notificationsService } from '@/api/graphql/notifications/notifications.service';

declare const google: any;

export default defineComponent({
  name: 'app',
  components: { OceanLoginPopup, LoginPopup },
  setup: () => {
    const { Device } = Plugins;
    const { t } = useI18n();
    const f7params = {
      name: 'OCEANHACK',
      theme: 'ios',
      routes: routes,
      dialog: {
        buttonOk: t('buttons.ok'),
        buttonCancel: t('buttons.cancel'),
      },
      photoBrowser: {
        popupCloseLinkText: t('buttons.cancel'),
      }
    };
    const appAnimate = APP_ANIMATE;
    const state = reactive({
      platform: '',
    });
    const app = App();
    const session = Session();
    onBeforeMount(async () => {
      const info = await Device.getInfo();
      state.platform = info.platform;
    });
    onMounted(() => {
      f7ready(async () => {
        registerNotifications();
        app.initAppEvents();
        app.initTransitionGuard();
        await session.initRegistration();
        if (session.state.license) {
          await session.initUser();
        }
        hideSplashScreen();
        if (DEBUG) createDebugToast();
      });
    });
    watch(() => app.state.fcmToken, async (newVal) => {
      if (newVal && newVal !== '') {
        await notificationsService.setUser({username: session.state.username, token: app.state.fcmToken });
      }
    })
    return { f7params, appAnimate, state };
  },
});
</script>

<style lang="scss">
@import "assets/styles/main.scss";
</style>
