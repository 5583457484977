import { Dom7 } from 'framework7';
import { f7 } from 'framework7-vue';
import { i18n } from '@/plugins/vue-i18n';

const { t } = i18n.global;

export const hideSplashScreen = () => Dom7('.splash-screen').hide();

export const createDebugToast = () => f7.toast.create({
  icon: '<i class="la la-exclamation-triangle"></i>',
  text: t('warnings.debug'),
  position: 'bottom',
  closeTimeout: 1000,
}).open();

export const createNotification = (message, status) => {
  f7.notification.create({
    icon: `<i class="
           ${status >= 300 ? 'text-color-red las la-exclamation-triangle' : 'text-color-blue las la-thumbs-up'}"></i>`,
    title: t(`statusCode.${status}`),
    titleRightText: status === 999 ? t('errors.connectionError') : status,
    text: message,
    closeOnClick: true,
    closeTimeout: 3000,
  }).open();
};
