export const APP_TRANSITION = '';
export const APP_ANIMATE = false;
export const APP_VERSION = '0.0.1';
export const DEBUG = process.env.NODE_ENV !== 'production';
export const REGISTRATION_KEY = 'REGISTRATION_KEY';
export const ACCESS_TOKEN_KEY = 'ACCES_TOKEN_KEY';
export const GCM_SERVER_KEY = 'BHceIFHHwKhrgXBhG3YMmeNg0_ytsqGocqPGzla9ibA80mgysk7AUQX2z1c1dXhRbTICfmribyhYW8pNkq_bwbM';
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyACpHcR-VhKLfHVQ1LFzPN0jtfzZeJ06Sc",
  authDomain: "ocean-hack.firebaseapp.com",
  databaseURL: "https://ocean-hack.firebaseio.com",
  projectId: "ocean-hack",
  storageBucket: "ocean-hack.appspot.com",
  messagingSenderId: "528329195473",
  appId: "1:528329195473:web:f44bbd0f1c8f314cf9eaf0",
  measurementId: "G-2ZMLGFYJ6Z"
};
