import { APP_TRANSITION } from '@/core/config/constants.config';
import HomePage from '@/views/HomePage.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';

const mainRoutes = [
  // { path: '', componentPath: '' },
];

const mountRoute = (resolve, reject, routePath) => {
  const isAuthenticated = true;
  if (isAuthenticated) {
    const vueComponent = () => import (`../views/${routePath}.vue`);
    vueComponent().then((vc) => {
      resolve({ component: vc.default });
    });
  } else {
    reject();
  }
};

const mapRoutes = (a, transition = '') => {
  return a.map(r => {
    return {
      path: r.path,
      async: function ({ resolve, reject }) {
        mountRoute(resolve, reject, r.componentPath);
      },
      ...(transition !== '' ? {options: {transition}} : {}),
    };
  });
};

export const routes = [
  { path: '/'               , component: HomePage       , ...(APP_TRANSITION !== '' ? {options: {transition: APP_TRANSITION}} : {}) },
  ...mapRoutes(mainRoutes       , APP_TRANSITION),
  { path: '/index.html'     , redirect: '/'             , ...(APP_TRANSITION !== '' ? {options: {transition: APP_TRANSITION}} : {}) },
  { path: '(.*)'            , component: NotFoundPage   , ...(APP_TRANSITION !== '' ? {options: {transition: APP_TRANSITION}} : {}) },
];
