import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { Plugins } from '@capacitor/core';
import { ACCESS_TOKEN_KEY } from '@/core/config/constants.config';

const { Storage } = Plugins;

// HTTP connection to the API
const httpLink = createHttpLink({
// You should use an absolute URL here
  uri: (process.env.NODE_ENV === 'production') ? 'https://oceanhack.tahe.dev/graphql' : `http://${window.location.hostname}:5000/graphql`,
//   uri: 'http://localhost:5000/graphql',
});

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await Storage.get({ key: ACCESS_TOKEN_KEY });
  console.log(accessToken.value);
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken.value}` : '',
    },
  };
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache,
});