
import { defineComponent } from 'vue';
import CheckTab from '@/views/tabs/CheckTab.vue';
import SettingsTab from '@/views/tabs/SettingsTab.vue';
import { Session } from '@/compositions/session.composition';

export default defineComponent({
  name: 'home-page',
  components: { SettingsTab, CheckTab },
  setup: () => {
    const { state: sessionState } = Session();
    return { sessionState };
  }
});
