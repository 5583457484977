import moment from 'moment';
import * as numeral from 'numeral';
import 'moment/locale/es';

numeral.register('locale', 'es', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  currency: {
    symbol: '€',
  }
});

numeral.locale('es');
numeral.defaultFormat('0,00€');
moment.locale('es');

export const formatDate = (date, format) => moment(date).format(format);
export const formatNumber = (n, format = '0.[00]') => numeral(n).format(format);

export const registerGlobalFilters = (app) => {
  app.config.globalProperties.$filters = {
    formatDate,
    formatNumber,
  };
};