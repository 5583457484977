import { CHECK, LOGIN_OCEAN_GQL, RELOGIN_OCEAN_GQL, SHOW_TODAY } from './ocean.queries';
import { apolloClient } from '@/plugins/apollo-client';

const showToday = () => apolloClient.query({ query: SHOW_TODAY, fetchPolicy: 'no-cache' });
const check = ({ manual, lat, lng }) => apolloClient.mutate({ mutation: CHECK, variables: { manual, lat, lng } });
const loginOcean = ({ username, password }) => apolloClient.mutate({ mutation: LOGIN_OCEAN_GQL, variables: { username, password }});
const reloginOcean = ({ username }) => apolloClient.mutate({ mutation: RELOGIN_OCEAN_GQL, variables: { username }});

export const oceanService = {
  showToday,
  check,
  loginOcean,
  reloginOcean,
};
