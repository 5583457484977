<template>
  <f7-login-screen class="login-popup" :opened="state.needLoginOcean">
    <f7-page login-screen>
      <f7-login-screen-title>
        <img src="@/assets/images/ocean-hack.png" alt="" style="max-width: 100%;">
      </f7-login-screen-title>
      <f7-list form>
        <f7-list-input
            :label="$t('inputs.username.label')"
            type="text"
            :placeholder="$t('inputs.username.placeholder')"
            :value="loginState.username"
            @input="loginState.username = $event.target.value"
            @keyup.enter="signIn"
        ></f7-list-input>
        <f7-list-input
            :label="$t('inputs.password.label')"
            type="text"
            :placeholder="$t('inputs.password.placeholder')"
            :value="loginState.password"
            @input="loginState.password = $event.target.value"
            @keyup.enter="signIn"
        ></f7-list-input>
      </f7-list>
      <f7-block>
        <f7-button large @click="signIn" :disabled="loginState.signInDisabled">
          <strong class="font-size-16px">{{ $t('buttons.login') }}</strong>
        </f7-button>
        <f7-block-footer>
          <span class="text-color-red" v-html="$t('text.loginInfo.line3')"></span><br>
          <span v-html="$t('text.loginInfo.line2', {year: $filters.formatDate(appState.today, 'YYYY'), version: appVersion})"></span>
        </f7-block-footer>
      </f7-block>
    </f7-page>
  </f7-login-screen>
</template>
<script lang="ts">
import { computed, defineComponent, reactive } from 'vue';
import { Session } from '@/compositions/session.composition';
import { App } from '@/compositions/app.composition';
import { APP_VERSION } from '@/core/config/constants.config';

export default defineComponent({
  name: 'ocean-login-popup',
  setup: () => {
    const { state, oceanLogin } = Session();
    const { state: appState } = App();
    const appVersion = APP_VERSION;
    const loginState = reactive({
      username: '',
      password: '',
      pwdVisible: false,
      signInDisabled: computed(() => loginState.username === '' || loginState.password === ''),
    });
    const signIn = async () => {
      if (loginState.signInDisabled) return;
      let { username, password } = loginState;
      username = username.trim().toUpperCase();
      password = password.trim();
      oceanLogin({ username, password });
    };
    return { state, loginState, appState, signIn, appVersion };
  },
});
</script>
<style lang="scss">
.login-screen-content {
  max-width: 90%;
  margin: auto;
}
</style>