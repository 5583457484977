import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// My Plugins
import { registerGlobalFilters } from '@/helpers/filters.helpers';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { apolloClient } from '@/plugins/apollo-client';
import { i18n } from '@/plugins/vue-i18n';

// Init App
const app = createApp(App);

// Register Framework7 Vue components
registerComponents(app);

// Register Custom Filters
registerGlobalFilters(app);

app.use(i18n);
app.provide(DefaultApolloClient, apolloClient);
app.mount('#app');
