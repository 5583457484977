export const es = {
  text: {
    appName: 'OCEANHACK',
    loginInfo: {
      line1: `Introduce tu DNI en usuario, y asocia la licencia de <br><strong>Oceanhack</strong> que se te ha proporcionado`,
      line2: `Oceanhack &copy; {year} {version}`,
      line3: 'Parece que no tienes un toque de acceso válido en ocean, necesitas acceder de nuevo',
    },
    activateAuto: 'Activar automático',
    deactivateAuto: 'Desactivar automático',
    todayChecks: 'Marcajes de hoy',
  },
  menus: {

  },
  buttons: {
    login: 'Acceder',
    logout: 'Salir',
    back: 'Atrás',
    check: 'Ticar manual',
    pin: 'Cambiar ubicación'
  },
  inputs: {
    username: { label: 'Usuario', placeholder: 'Introduce el usuario' },
    license: { label: 'Licencia', placeholder: 'Introduce tu licencia' },
    password: { label: 'Contraseña', placeholder: 'Introduce tu contraseña' },
  },
  info: {

  },
  warnings: {
    debug: '¡ESTÁS EN MODO DESARROLLO!',
  },
  errors: {
    connectionError: 'Error de conexión',
    loginError: `Ha ocurrido un error al acceder a la aplicación: {error}`,
  }
};