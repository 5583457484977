import { computed, reactive } from 'vue';
import { i18n } from '@/plugins/vue-i18n';
import { createNotification } from '@/helpers/f7.helpers';
import { Plugins } from '@capacitor/core';
import { employeesService } from '@/api/graphql/employees/employees.service';
import { ACCESS_TOKEN_KEY, REGISTRATION_KEY } from '@/core/config/constants.config';
import { authService } from '@/api/graphql/auth/auth.service';
import { notificationsService } from '@/api/graphql/notifications/notifications.service';
import { App } from '@/compositions/app.composition';
import { oceanService } from '@/api/graphql/ocean/ocean.service';

const { t } = i18n.global;
const { Storage } = Plugins;

const state = reactive({
  accessToken: '',
  isAuthenticated: false,
  username: '',
  user: null,
  oceanAccessToken: computed(() => state.user ? (state.user as any).accessToken : ''),
  license: null,
  needLoginOcean: false,
});

const initUser = async () => {
  const { state: appState } = App();
  const { data: { employee } } = await oceanService.reloginOcean({ username: state.username });
  if ((employee as any).accessToken === '') {
    console.log('NECESITAS VOLVER A ACCEDER A OCEAN');
    state.needLoginOcean = true;
  } else {
    state.username = employee.username;
    state.user = { ...employee };
    state.isAuthenticated = true;
    console.log('HE INICIADO EL USUARIO');
  }
};

const initRegistration = async () => {
  const { state: appState } = App();
  const reg = await Storage.get({ key: REGISTRATION_KEY });
  let registration = null;
  if (reg.value) {
    registration = JSON.parse(reg.value);
  }
  if (registration) {
    const at = await Storage.get({ key: ACCESS_TOKEN_KEY });
    const accessToken = at.value;
    if (accessToken) {
      state.accessToken = accessToken;
      const { data: { profile: employee } } = await employeesService.profile();
      state.username = employee.username;
      state.user = { ...employee };
      state.isAuthenticated = true;
    } else {
      const { data: { login: { token: accessToken, employee } } } = await authService.login({ username: (registration as any).username, device: (registration as any).device });
      await Storage.set({ key: ACCESS_TOKEN_KEY, value: accessToken });
      state.accessToken = accessToken;
      state.username = employee.username;
      state.user = { ...employee };
      state.isAuthenticated = true;
      initUser();
    }
    state.license = registration;
    if (appState.fcmToken) {
      await notificationsService.setUser({username: state.username, token: appState.fcmToken });
    }
  }
};

const login = async ({ username, license }) => {
  // TODO IMPLEMENTAR
  try {
    const { data: { register: { employee, license: ldata }} } = await employeesService.register({ username, license });
    await Storage.set({ key: REGISTRATION_KEY, value: JSON.stringify(ldata) });
    await initRegistration();
    state.license = ldata;
    state.username = employee.username;
    state.user = { ...employee };
    state.isAuthenticated = true;
  } catch (error) {
    state.isAuthenticated = false;
    const message = t(error.message);
    createNotification(t('errors.loginFailed', { message }), error.statusCode);
  }
};

const oceanLogin = async({ username, password }) => {
  try {
    const { data: { employee } } = await oceanService.loginOcean({ username, password });
    state.username = employee.username;
    state.user = { ...employee };
    state.isAuthenticated = true;
    state.needLoginOcean = false;
  } catch (error) {
    createNotification(error, error.statusCode);
  }
};

const setLocation = async (location) => {
  console.log(location);
  const { data: employee } = await employeesService.setLocation({ username: (state.user as any).username,
    lat: location.lat, lng: location.lng });
  (state.user as any).defaultLocation = { ...(employee as any).defaultLocation };
};

const toggleAutomaticCheck = async (selected) => {
  const { data: { toggleAutomaticCheck } } = await employeesService.toggleAutomaticCheck({ automaticCheck: selected });
  (state.user as any).automaticCheck = toggleAutomaticCheck;
};

const logout = () => {
  state.isAuthenticated = false;
}

export const Session = () => {
  return { state, login, logout, initRegistration, toggleAutomaticCheck, initUser, setLocation, oceanLogin };
};