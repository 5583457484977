<template>
  <f7-page>
    <f7-navbar title="Static Tabs"></f7-navbar>
    <f7-block strong inset>
      Not Found
    </f7-block>
  </f7-page>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'not-found-page',
});
</script>