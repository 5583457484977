import gql from 'graphql-tag';

export const LOGIN = `
  login(data: {
    username: $username,
    device: $device,
  }) {
    token
    employee {
      username
      oceanId
      accessToken
      defaultLocation {
        lat
        lng
      }
    }
  }
`;

export const LOGIN_GQL = gql`mutation Register($username: String!, $device: String!) { ${LOGIN} }`;
