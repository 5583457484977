import { REGISTER_CLIENT_GQL, SET_USER_TO_TOKEN_GQL } from './notifications.queries';
import { apolloClient } from '@/plugins/apollo-client';

export const registerClient = ({token, type, app}) => apolloClient.mutate({ mutation: REGISTER_CLIENT_GQL, variables: { token, type, app } });
export const setUser = ({ username, token }) => apolloClient.mutate({ mutation: SET_USER_TO_TOKEN_GQL, variables: { username, token } });

export const notificationsService = {
  registerClient,
  setUser,
};
