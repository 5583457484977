import { PROFILE_GQL, REGISTER_LICENSE_GQL, SET_LOCATION_GQL, TOGGLE_AUTO_CHECK_GQL } from './employees.queries';
import { apolloClient } from '@/plugins/apollo-client';

const register = ({username, license }) => apolloClient.mutate({ mutation: REGISTER_LICENSE_GQL, variables: { username, license } });
const profile = () => apolloClient.query({ query: PROFILE_GQL });
const toggleAutomaticCheck = ({ automaticCheck }) => apolloClient.mutate({ mutation: TOGGLE_AUTO_CHECK_GQL, variables: { automaticCheck }});
const setLocation = ({ username, lat, lng }) => apolloClient.mutate({ mutation: SET_LOCATION_GQL, variables: { username, lat, lng }});

export const employeesService = {
  register,
  profile,
  toggleAutomaticCheck,
  setLocation,
}
