import { LOGIN_GQL } from './auth.queries';
import { apolloClient } from '@/plugins/apollo-client';

const login = ({username, device }) =>
  apolloClient.mutate({mutation: LOGIN_GQL, variables: { username, device }});


export const authService = {
  login,
}
