import firebase from 'firebase/app';
import 'firebase/messaging';
import { Capacitor, Plugins } from '@capacitor/core';
import { FIREBASE_CONFIG, GCM_SERVER_KEY } from '@/core/config/constants.config';
import { App } from '@/compositions/app.composition';
import { notificationsService } from '@/api/graphql/notifications/notifications.service';

const initPwaNotifications = () => {
  // IMPLEMENTACIÓN DEL SERVICE WORKER PARA LA PWA
  const { setFcmToken } = App();

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then( async registration => {
        // console.log('SW registered: ', registration);
        // setSw(registration);
        firebase.initializeApp(FIREBASE_CONFIG);
        const messaging = firebase.messaging();
        messaging.usePublicVapidKey(GCM_SERVER_KEY);
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        messaging.getToken().then(async (currentToken) => {
          if (currentToken) {
            console.log('Current Token');
            console.log(currentToken);
            await setFcmToken(currentToken);
            await notificationsService.registerClient({token: currentToken, type: Capacitor.platform, app: 'oceanhack' });
            // sendTokenToServer(currentToken);
            // updateUIForPushEnabled(currentToken);
          } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.');
            // Show permission UI.
            // updateUIForPushPermissionRequired();
            // setTokenSentToServer(false);
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // showToken('Error retrieving Instance ID token. ', err);
          // setTokenSentToServer(false);
        });

        // Callback fired if Instance ID token is updated.
        messaging.onTokenRefresh(() => {
          messaging.getToken().then((refreshedToken) => {
            console.log('Token refreshed.');
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            // setTokenSentToServer(false);
            // Send Instance ID token to app server.
            // sendTokenToServer(refreshedToken);
            // ...
          }).catch((err) => {
            console.log('Unable to retrieve refreshed token ', err);
            // showToken('Unable to retrieve refreshed token ', err);
          });
        });

        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        // - the user clicks on an app notification created by a service worker
        //   `messaging.setBackgroundMessageHandler` handler.
        messaging.onMessage((payload) => {
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            icon: '../static/icons/256x256.png',
          };
          return registration.showNotification(notificationTitle,
            notificationOptions);
        });
        //   console.log(messaging);
        //
        //   const token = await messaging.getToken({
        //     registration,
        //   });
        //   // fcmAdminService.registerClient({token, type: this.platform});
        //
        //   messaging.onMessage((payload) => {
        //     console.log('Message received. ', payload);
        //     const options = {
        //       body: 'Testing Our Notification',
        //       icon: './bell.png'
        //     };
        //     registration.showNotification('PWA Notification!', options);
        //   });
        //
        //
        //   registration.pushManager.subscribe({
        //     userVisibleOnly: true,
        //     applicationServerKey: urlBase64ToUint8Array(GCM_SERVER_KEY),
        //   }).then(sub => {
        //     console.log(sub);
        //   }).catch(error => {
        //     console.log(error);
        //   });
        // }).catch(registrationError => {
        //   console.log(registrationError);
      });
    });
  }
};

const initDeviceNotifications = () => {
  const { PushNotifications } = Plugins;
  const { setFcmToken } = App();

  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  PushNotifications.requestPermission().then( async result => {
    if (result.granted) {
      // Register with Apple / Google to receive push via APNS/FCM
      // alert(JSON.stringify(result));
      // TODO VER QUE ES ESTO
      PushNotifications.register();
      // await store.dispatch('app/setFcmToken', currentToken);
      // await notificationsService.registerClient({token: token.value, type: Capacitor.platform, app: 'oceanhack' });
    } else {
      // Show some error
    }
  });

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration',
    async (token) => {
      await setFcmToken(token.value);
      await notificationsService.registerClient({token: token.value, type: Capacitor.platform, app: 'oceanhack' });
    }
  );

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError',
    (error) => {
      // alert('Error on registration: ' + JSON.stringify(error));
    }
  );

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener('pushNotificationReceived',
    (notification) => {
      // alert('Push received: ' + JSON.stringify(notification));
    }
  );

  // Method called when tapping on a notification
  PushNotifications.addListener('pushNotificationActionPerformed',
    (notification) => {
      // alert('Push action performed: ' + JSON.stringify(notification));
    }
  );
};

export const registerNotifications = () => {
  console.log(Capacitor.platform || '');
  if (['web'].includes((Capacitor.platform as any))) {
    initPwaNotifications();
  } else if (!['electron'].includes((Capacitor.platform as any))) {
    initDeviceNotifications();
  }
};
