import gql from 'graphql-tag';

export const REGISTER = `
  register(data: { username: $username, license: $license }) {
    license {
      license
      device
      username
    }
    employee {
      username
      oceanId
      accessToken
      automaticCheck
      defaultLocation {
        lat
        lng
      }
    }
  }
`;

export const PROFILE = `
  profile {
    username
    oceanId
    accessToken
    automaticCheck
    defaultLocation {
      lat
      lng
    }
  }
`;

export const TOGGLE_AUTO_CHECK = `
  toggleAutomaticCheck(automaticCheck: $automaticCheck)
`;

export const SET_LOCATION = `
  employee: setLocation(username: $username, lat: $lat, lng: $lng) {
    username
    oceanId
    accessToken
    automaticCheck
    defaultLocation {
      lat
      lng
    }
  }
`;

export const REGISTER_LICENSE_GQL = gql`mutation Register($username: String!, $license: String!) { ${REGISTER} }`;
export const PROFILE_GQL = gql`query { ${PROFILE} }`;
export const TOGGLE_AUTO_CHECK_GQL = gql`mutation ToggleAutomaticCheck($automaticCheck: Boolean!) { ${TOGGLE_AUTO_CHECK} }`;
export const SET_LOCATION_GQL = gql`mutation SetLocation($username: String!, $lat: Float!, $lng: Float!) { ${SET_LOCATION} }`;