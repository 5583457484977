<template>
  <f7-page :page-content="false">
    <div style="position: absolute; right: 0" class="margin-half">
      <strong class="text-color-gray test" v-if="sessionState.user">{{sessionState.user.username}}</strong>
    </div>
    <f7-toolbar tabbar labels bottom>
      <f7-link tab-link="#tab-check" tab-link-active icon="las la-fingerprint" text="Ticaje"></f7-link>
      <f7-link tab-link="#tab-settings" icon="las la-wrench" text="Ajustes"></f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="tab-check" class="page-content" tab-active>
        <check-tab></check-tab>
      </f7-tab>
      <f7-tab id="tab-settings" class="page-content">
        <settings-tab></settings-tab>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import CheckTab from '@/views/tabs/CheckTab.vue';
import SettingsTab from '@/views/tabs/SettingsTab.vue';
import { Session } from '@/compositions/session.composition';

export default defineComponent({
  name: 'home-page',
  components: { SettingsTab, CheckTab },
  setup: () => {
    const { state: sessionState } = Session();
    return { sessionState };
  }
});
</script>