<template>
  <f7-block>
    <img class="margin-vertical display-flex justify-content-center"
        src="@/assets/images/ocean-hack.png" alt="" style="margin: auto; max-width: 320px; width: 100%;">
    <template v-if="sessionState.user">
      <div class="display-flex flex-direction-row align-items-center justify-content-center margin-40">
        <f7-toggle fill large :checked="sessionState.user.automaticCheck" @change="toggleAutomaticCheck($event.target.checked)"></f7-toggle>
        <strong class="margin-left">{{ sessionState.user.automaticCheck ? $t('text.deactivateAuto') : $t('text.activateAuto') }}</strong>
      </div>
      <div class="display-flex justify-content-center margin-20">
        <f7-button class="check-button margin" large fill @click="check()">{{ $t('buttons.check') }}</f7-button>
      </div>
      <div v-if="state.todayChecks" class="display-flex flex-direction-column align-items-center justify-content-center">
        <div class="margin-bottom display-flex flex-direction-row align-items-center justify-content-center">
          <span class="text-color-gray">{{ $t('text.todayChecks') }}</span>
          <f7-button class="margin-left" icon="las la-sync" icon-size="27" @click="getTodayChecks()"></f7-button>
        </div>
        <div v-for="(marcaje, index) in state.todayChecks.Marcajes" :key="index" class="font-size-14 font-weight-bold">
          <div v-if="marcaje.MarcajeEntrada" class="display-flex flex-direction-row align-items-center margin-vertical-half">
            <div class="margin-right"><f7-icon size="20" icon="las la-chevron-right" style="color: #ADC32D"></f7-icon></div>
            <strong>{{marcaje.MarcajeEntrada.Hora}}</strong>
          </div>
          <div v-if="marcaje.MarcajeSalida" class="display-flex flex-direction-row align-items-center margin-vertical-half">
            <div class="margin-right"><f7-icon size="20" icon="las la-chevron-left" style="color: #AD3A32"></f7-icon></div>
            <strong>{{marcaje.MarcajeSalida.Hora}}</strong>
          </div>
          <hr class="solid" />
        </div>
      </div>
    </template>
  </f7-block>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, watch } from 'vue';
import { Session } from '@/compositions/session.composition';
import { oceanService } from '@/api/graphql/ocean/ocean.service';
import { Plugins } from '@capacitor/core';

const { Geolocation, Network } = Plugins;

export default defineComponent({
  name: 'check-tab',
  setup: () => {
    const { state: sessionState, toggleAutomaticCheck } = Session();
    const state = reactive({
      todayChecks: null,
    })
    const getTodayChecks = async () => {
      try {
        const { data: { showToday }} = await oceanService.showToday();
        state.todayChecks = showToday;
      } catch (err) {
        console.log(err);
      }
    };
    const check = async () => {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        const { data } = await oceanService.check({ manual: true,
          lat: coordinates.coords.latitude, lng: coordinates.coords.longitude });
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };
    onMounted(() => {
      getTodayChecks();
    });
    watch(() => sessionState.oceanAccessToken, (value, oldValue) => {
      console.log('Ha cambiado el token de ocean');
      getTodayChecks();
    });
    return { sessionState, toggleAutomaticCheck, state, getTodayChecks, check };
  },
});
</script>
<style lang="scss">
.margin-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>