
import { defineComponent, onMounted, reactive, watch } from 'vue';
import { Session } from '@/compositions/session.composition';
import { oceanService } from '@/api/graphql/ocean/ocean.service';
import { Plugins } from '@capacitor/core';

const { Geolocation, Network } = Plugins;

export default defineComponent({
  name: 'check-tab',
  setup: () => {
    const { state: sessionState, toggleAutomaticCheck } = Session();
    const state = reactive({
      todayChecks: null,
    })
    const getTodayChecks = async () => {
      try {
        const { data: { showToday }} = await oceanService.showToday();
        state.todayChecks = showToday;
      } catch (err) {
        console.log(err);
      }
    };
    const check = async () => {
      try {
        const coordinates = await Geolocation.getCurrentPosition();
        const { data } = await oceanService.check({ manual: true,
          lat: coordinates.coords.latitude, lng: coordinates.coords.longitude });
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };
    onMounted(() => {
      getTodayChecks();
    });
    watch(() => sessionState.oceanAccessToken, (value, oldValue) => {
      console.log('Ha cambiado el token de ocean');
      getTodayChecks();
    });
    return { sessionState, toggleAutomaticCheck, state, getTodayChecks, check };
  },
});
