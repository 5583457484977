import gql from 'graphql-tag';

export const REGISTER_CLIENT_GQL = gql`
  mutation RegisterClient($token: String!, $type: String!, $app: String!) {
    registerClient(fcmClient: {
      token: $token,
      type: $type,
      app: $app,
    }) {
      token
      type
      groups
      username
      app
    }
  }
`;

export const SET_USER_TO_TOKEN_GQL = gql`
  mutation SetUserToToken($username: String!, $token: String!) {
    setUserToToken(data: {
      username: $username,
      token: $token,
    }) {
      token
      type
      groups
      username
      app
    }
  }
`;
