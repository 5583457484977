
import { computed, defineComponent, reactive } from 'vue';
import { Session } from '@/compositions/session.composition';
import { App } from '@/compositions/app.composition';
import { APP_VERSION } from '@/core/config/constants.config';

export default defineComponent({
  name: 'ocean-login-popup',
  setup: () => {
    const { state, oceanLogin } = Session();
    const { state: appState } = App();
    const appVersion = APP_VERSION;
    const loginState = reactive({
      username: '',
      password: '',
      pwdVisible: false,
      signInDisabled: computed(() => loginState.username === '' || loginState.password === ''),
    });
    const signIn = async () => {
      if (loginState.signInDisabled) return;
      let { username, password } = loginState;
      username = username.trim().toUpperCase();
      password = password.trim();
      oceanLogin({ username, password });
    };
    return { state, loginState, appState, signIn, appVersion };
  },
});
