import { reactive } from 'vue';
import { Dom7 } from 'framework7';
import { f7 } from 'framework7-vue';

const state = reactive({
  width: window.innerWidth,
  isLoadingRoute: false,
  currentRoute: '',
  loading: false,
  today: new Date(),
  fcmToken: '',
});

const initAppEvents = () => {
  window.addEventListener('resize', () => {
    state.width = window.innerWidth;
  });
  window.addEventListener('orientationchange', () => {
    state.width = window.innerWidth;
  });
  document.addEventListener('visibilitychange', async () => {
    // if (!document.hidden) checkTicaje();
  });
};

const initTransitionGuard = () => {
  Dom7('.view-main').on('page:beforein', () => {
    state.currentRoute = f7.views.main.router.currentRoute.url;
  });
  Dom7('.view-main').on('page:afterin', () => {
    Dom7('.link.back').removeClass('disable-back-button');
    state.isLoadingRoute = false;
  });
  Dom7('.view-main').on('page:beforeout', () => {
    Dom7('.link.back').addClass('disable-back-button');
    state.isLoadingRoute = true;
    state.currentRoute = '';
  });
};

const setLoading = (loading) => {
  state.loading = loading;
};

const setFcmToken = (fcmToken) => {
  state.fcmToken = fcmToken;
};

export const App = () => {
  return { state, initAppEvents, initTransitionGuard, setLoading, setFcmToken };
}