import gql from 'graphql-tag';

export const SHOW_TODAY = gql`query {
  showToday {
    Marcajes
  }
}`;

export const LOGIN_OCEAN = `
  employee: loginOcean(username: $username, password: $password) {
    username
    oceanId
    accessToken
    automaticCheck
    defaultLocation {
      lat
      lng
    }
  }
`;

export const RELOGIN_OCEAN = `
  employee: reloginOcean(username: $username) {
    username
    oceanId
    accessToken
    automaticCheck
    defaultLocation {
      lat
      lng
    }
  }
`;

export const CHECK = gql`mutation Check($manual: Boolean, $lat: Float, $lng: Float) { check(manual: $manual, lat: $lat, lng: $lng) }`;
export const LOGIN_OCEAN_GQL = gql`mutation LoginOcean($username: String!, $password: String!) { ${LOGIN_OCEAN} }`;
export const RELOGIN_OCEAN_GQL = gql`mutation ReloginOcean($username: String!) { ${RELOGIN_OCEAN} }`;
