<template>
  <div class="map-wrapper">
    <div id="map"></div>
    <div class="pin-button display-flex justify-content-center margin-20" style="position: absolute;">
      <f7-button class="margin" :disabled="!state.newMarker" large fill @click="saveLocation()">{{ $t('buttons.pin') }}</f7-button>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, watch } from 'vue';
import { Session } from '@/compositions/session.composition';

declare const google: any;

export default defineComponent({
  name: 'settings-tab',
  setup: () => {
    const { state: sessionState, setLocation } = Session();
    const state = reactive({
      map: null,
      marker: null,
      newMarker: null,
      location: computed(() => sessionState.user && (sessionState.user as any).defaultLocation ? {
        lat: (sessionState.user as any).defaultLocation.lat,
        lng: (sessionState.user as any).defaultLocation.lng,
      }: { lat: 0, lng: 0 }),
    })
    const setMarker = () => {
      state.marker = new google.maps.Marker({
        map: state.map,
        draggable: false,
        position: state.location,
        icon: {
          url: 'https://maps.google.com/mapfiles/ms/icons/pink-dot.png',
        },
      });
    };
    const saveLocation = async () => {
      const loc = { lat: (state.newMarker as any).position.lat(), lng: (state.newMarker as any).position.lng() };
      await setLocation(loc);
      (state.marker as any).setPosition((state.newMarker as any).position);
      (state.newMarker as any).setMap(null);
      (state.newMarker as any).setVisible(false);
      state.newMarker = null;
    };
    onMounted(() => {
      state.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: { lat: state.location.lat, lng: state.location.lng },
        zoom: 16,
      });
      (state.map as any).addListener('click', (mapsMouseEvent) => {
        console.log(mapsMouseEvent.latLng.lat());
        if (state.newMarker) {
          (state.newMarker as any).setPosition({ lat: mapsMouseEvent.latLng.lat(), lng: mapsMouseEvent.latLng.lng() });
        } else {
          state.newMarker = new google.maps.Marker({
            map: state.map,
            draggable: false,
            animation: google.maps.Animation.BOUNCE,
            position: mapsMouseEvent.latLng,
            icon: {
              url: 'https://maps.google.com/mapfiles/ms/icons/purple-dot.png',
            },
          });
        }
      })
      setMarker();
    });
    watch(() => sessionState.user,  () => {
      const center = new google.maps.LatLng(state.location.lat, state.location.lng);
      if (center && state.map) {
        (state.map as any).panTo(center);
        (state.marker as any).setPosition(state.location);
      }
    });
    return { saveLocation, state };
  }
});
</script>
<style lang="scss">
.map-wrapper {
  width: 100%;
  height: 100%;
}
#map {
  height: 100%;
}
.pin-button {
  bottom: 40px;
  z-index: 9999;
}
</style>